import { useEffect } from 'react'

export const isMobileView = () => {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i]

  return toMatch.some(toMatchItem => {
    return navigator.userAgent.match(toMatchItem)
  })
}

export const useSetFocus = (inputRef: React.RefObject<HTMLInputElement>, onlySetForDesktopView?: boolean) => {
  useEffect(() => {
    if ((onlySetForDesktopView && !isMobileView()) || !onlySetForDesktopView) {
      if (inputRef !== null && inputRef.current) {
        inputRef.current.focus()
      }
    }
  })
}

export default useSetFocus
