import * as React from 'react'
import Header, { IHeader } from './Header'
import { IAccount } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Account'
import { IContentBlock } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/OmniPage/interfaces'
import CheckoutHeader from './CheckoutHeader'
import MorCartContext, { IMorCartContextProviderProps } from '../../Context/MorCartContext'

export interface ILayoutProps {
  // TODO: create proper interfaces
  pageProps: any
  data: IHeader[]
  contentblocks: IContentBlock[]
  account: IAccount
  onChangeLocation: () => void
}
/**
 * The core wrapper for all pages
 */
const HeaderLayout = (props: ILayoutProps) => {
  const { count, methods } = React.useContext(MorCartContext.Context) as IMorCartContextProviderProps

  const getCart = (): void => {
    //
  }

  /**
   * Specific sections of the app will require a different header
   */
  const { pageProps } = props
  if (pageProps && pageProps.header) {
    const { type } = pageProps.header
    if (type && type === 'minified') {
      return <CheckoutHeader {...props.pageProps.header} />
    }
  }

  return (
    <React.Fragment>
      <div>
        <Header
          header={props.data}
          count={count}
          token={''}
          account={props.account}
          getCart={getCart}
          cartMethods={methods}
          contentblocks={props.contentblocks}
          onChangeLocation={props.onChangeLocation}
        />
      </div>
    </React.Fragment>
  )
}

export default HeaderLayout
