import * as React from 'react'
import HeaderTopNavigation from './TopNavigation'
import MobileMenu from './MobileMenu/MobileMenu'
import { ICategory } from './interfaces'
import css from './Header.scss'
import { IAccount } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Account'
import { IContentBlock } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/OmniPage/interfaces'
import { onUnbxdSearch } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Services/Unbxd/UnbxdController'
import { ICartMethods } from '../../Context/MorCartContext'
import { getStoreCode } from '../../../Utils/getStoreCodeFromCookie'
import { getNewPageResponse, getOnSaleResponse } from './DynamicMenu/menuUtils'
import { getCookie, setCookie } from '../../../../utils/cookie'
import { IDynamicOptionsCookie } from '../../../StoreLocator/interfaces'

export interface IHeader {
  categories: ICategory[]
  id: string
  title: string
}

interface IProps {
  header: IHeader[] // TODO: give proper data type
  count: number
  token: string
  account: IAccount
  getCart: () => void
  cartMethods: ICartMethods
  contentblocks: IContentBlock[]
  onChangeLocation: () => void
}

class Header extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props)
    this.state = { showSale: false, storeCode: 'XX', showNew: false, showOverlay: false }
  }

  async componentDidMount() {
    this.props.getCart()
    await this.checkShowDynamicOptionsOnHeader(true)
  }

  async checkShowDynamicOptionsOnHeader(onLoad: boolean) {
    const dynamicOptionsCookie: string | undefined = getCookie(IDynamicOptionsCookie.DYNAMIC_OPTIONS)
    const storeCode = getStoreCode()
    let sNew
    let sSale
    if (dynamicOptionsCookie && onLoad) {
      const { showNew, showSale } = JSON.parse(dynamicOptionsCookie)
      sNew = showNew
      sSale = showSale
    } else {
      sNew = await getNewPageResponse(storeCode)
      sSale = await getOnSaleResponse(storeCode)
      setCookie(
        IDynamicOptionsCookie.DYNAMIC_OPTIONS,
        {
          showNew: sNew,
          showSale: sSale,
        },
        1,
      )
    }

    this.setState({ showNew: sNew, showSale: sSale, storeCode })
  }

  handleLocationChange() {
    this.props.onChangeLocation()
    return this.checkShowDynamicOptionsOnHeader(false)
  }

  onSearchTextUpdate = (search: string) => {
    if (search) {
      onUnbxdSearch(search)
      location.href = `/search?q=${search}`
    }
  }

  sortItemsByTreeIndex = (items: ICategory[]) => {
    return items.slice().sort((a: ICategory, b: ICategory) => a.treeIndex - b.treeIndex)
  }

  render() {
    const { contentblocks } = this.props
    const { showNew, showSale, storeCode } = this.state

    return (
      <React.Fragment>
        {this.state.showOverlay && <div className={css.menuRowOverlay}></div>}
        <div
          className={
            contentblocks && contentblocks.length > 0
              ? `${css.categoryMenu} ${css.categoryMenuWithContentBlock}`
              : css.categoryMenu
          }
        >
          <HeaderTopNavigation
            header={this.props.header}
            contentblocks={this.props.contentblocks}
            handleLocationChange={this.handleLocationChange.bind(this)}
            onSearchChange={this.onSearchTextUpdate}
            sortItemsByTreeIndex={this.sortItemsByTreeIndex}
            dynamicMenu={{ showNew, showSale, storeCode }}
            onMenuOver={() =>
              this.setState({
                showOverlay: true,
              })
            }
            onMenuBlur={() =>
              this.setState({
                showOverlay: false,
              })
            }
          />
        </div>
        <div className={css.mobileMenu}>
          <MobileMenu
            links={this.props.header}
            sortItemsByTreeIndex={this.sortItemsByTreeIndex}
            dynamicMenu={{ showNew, showSale, storeCode }}
            cartCount={this.props.count}
            contentblocks={this.props.contentblocks}
            onSearchChange={this.onSearchTextUpdate}
            onChangeLocation={this.props.onChangeLocation}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default Header
