import { ILocationData, ITagConfiguration } from '../interfaces'

const showContentBasedOnCurrentLocation = (
  usersCurrentLocation: ILocationData,
  geoTagConfiguration: ITagConfiguration,
) => {
  // by default, let's always show the block
  let showBlock: boolean = true
  // only check if geotagging is enabled
  if (geoTagConfiguration.enabled) {
    for (const tagGroup of geoTagConfiguration.groups) {
      if (tagGroup.tags.length > 0) {
        showBlock = false
      }
    }
    // fine out where the user actually is
    const { state, zip, city, code } = usersCurrentLocation
    for (const tagGroup of geoTagConfiguration.groups) {
      let currentProp: string = ''
      switch (tagGroup.type) {
        case 'state':
          currentProp = state
          break
        case 'zip':
          currentProp = zip
          break
        case 'city':
          currentProp = city
          break
        case 'code':
          currentProp = code || ''
          break
      }
      for (const tag of tagGroup.tags) {
        if (tag.value.value) {
          if (tag.value.value === currentProp) {
            showBlock = true
            break
          }
        } else if (tag.value === currentProp) {
          showBlock = true
          break
        }
      }
      if (showBlock) {
        break
      }
    }
  }
  return showBlock
}

export { showContentBasedOnCurrentLocation }
