import { has } from './lodash'

const isBot = (ctx: any) => {
  if (ctx && ctx.req) {
    const userAgent: string | undefined = has(ctx.req, ['headers', 'user-agent'])
    if (userAgent) {
      // eslint-disable-next-line no-console
      // console.log('User Agent', userAgent)
      return userAgent.indexOf('GoogleHC') !== -1
    }
  }
  return false
}
export default isBot
