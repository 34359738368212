import * as React from 'react'
import css from './InputError.scss'

export interface IProps {
  name: string
  errors: any
  touched: any
}

export default function InputError(props: IProps) {
  const { name, touched, errors } = props
  const hasError = touched[name] && errors[name]
  return (
    <div className={css.errorBox}>
      {hasError && (
        <div className={css.errorText}>
          <p>
            <i>{errors[name]}</i>
          </p>
        </div>
      )}
    </div>
  )
}
