import * as React from 'react'
import css from '../Layout.scss'

export default class FooterContainer extends React.Component<any> {
  public render() {
    return (
      <React.Fragment>
        <div className={css.fillFooter}>{this.props.children}</div>
      </React.Fragment>
    )
  }
}
