import * as React from 'react'
import css from './DynamicMenu.scss'
import { Container } from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Layout'
import { ICategory } from '../interfaces'
import HeaderLocation from '../HeaderLocation'
import { AppContextType } from 'next/dist/shared/lib/utils'
import { IDynamicMenu } from '../TopNavigation'

interface IMagentoCategoryItem {
  children: IMagentoCategory
  category_id: {
    $value: string
  }
  parent_id: {
    $value: string
  }
  name: {
    $value: string
  }
  level: {
    $value: number
  }
  is_active: {
    $value: number
  }
}
interface IMagentoCategory {
  item: IMagentoCategoryItem[] | IMagentoCategoryItem
}

interface IState {
  dropdownToRender: any
  delayedHandler: any
  isSelected: boolean[]
  isSelectedViewAll: boolean
}

interface IDynamicMenuProps extends AppContextType {
  dynamicMenu: IDynamicMenu
  showOverlay: () => void
  hideOverlay: () => void
}

export default class LinkMenu extends React.Component<any, IState> {
  constructor(props: IDynamicMenuProps) {
    super(props)
    this.state = {
      dropdownToRender: null,
      delayedHandler: null,
      isSelected: Array(20).fill(false),
      isSelectedViewAll: false,
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = event => {
    if (event.key === 'Escape') {
      this.hideDropdown()
    }
  }

  handleOnTab = (index: number, selected: boolean) => {
    const newSelectedState = [...this.state.isSelected]
    newSelectedState[index] = selected
    this.setState({ isSelected: newSelectedState })
  }

  createDropDownLinks(children: 0 | ICategory[] | undefined, item: ICategory) {
    const path = '/catalog/'
    return (
      <React.Fragment>
        <div className={css.dropDownContainer}>
          <a href={`${path}${item.slug}`} data-nav={item.title} data-zone="Navigation">
            <b>{item.title}</b>
          </a>
        </div>
        {children &&
          this.props.sortItemsByTreeIndex(children).map((childItem: ICategory, index: number) => {
            if (childItem.hidden) {
              return <React.Fragment key={index} />
            }
            return (
              <div key={index} className={`${css.dropDownContainer} ${css.itemTitleDropDown}`}>
                <a
                  style={this.state.isSelected[index] ? { color: '#399aca' } : { color: 'black' }}
                  href={`${path}${childItem.slug}`}
                  data-zone="Navigation"
                  data-sub-nav={childItem.title}
                  onFocus={() => this.handleOnTab(index, true)}
                  onBlur={() => this.handleOnTab(index, false)}
                >
                  {childItem.title}
                </a>
              </div>
            )
          })}
        <div key={item.title} className={`${css.dropDownContainer} ${css.itemTitleDropDown}`}>
          <a
            style={this.state.isSelectedViewAll ? { color: '#399aca' } : { color: 'black' }}
            href={`${path}${item.slug}-view-all`}
            onFocus={() => this.setState({ isSelectedViewAll: true })}
            onBlur={() => this.setState({ isSelectedViewAll: false })}
          >
            View All
          </a>
        </div>
      </React.Fragment>
    )
  }

  showDropdown = (item: any) => {
    this.hideDropdown()
    this.props.showOverlay()
    this.setState({
      delayedHandler: setTimeout(() => {
        this.setState({ dropdownToRender: item })
      }, 1),
    })
  }

  hideDropdown = () => {
    this.props.hideOverlay()
    this.setState({ isSelected: [] })
    this.setState({ dropdownToRender: null })
    clearTimeout(this.state.delayedHandler)
  }

  renderDropdown = () => {
    const children: 0 | ICategory[] | undefined =
      this.state.dropdownToRender.children &&
      this.state.dropdownToRender.children.length &&
      this.state.dropdownToRender.children
    return (
      <Container fluid={false}>
        <div className={css.displayFlexCont}>
          <div className={css.linksContainer}>{this.createDropDownLinks(children, this.state.dropdownToRender)}</div>
          <div className={css.dropDownImgContainer}>
            {this.state.dropdownToRender.flyoutImage && this.state.dropdownToRender.flyoutImage.length && (
              <a href={this.state.dropdownToRender.flyoutImage[0].flyoutImageLink || ''}>
                <img
                  className={css.image}
                  src={this.state.dropdownToRender.flyoutImage[0].flyoutImageUrl || ''}
                  alt={this.state.dropdownToRender.title}
                />
              </a>
            )}
          </div>
        </div>
      </Container>
    )
  }
  renderItem = (item: ICategory, index: number) => {
    if (item.hidden) {
      return <React.Fragment key={index} />
    }
    return (
      <div key={index} className={css.dropdown}>
        <a href={`/catalog/${item.slug}`}>
          <b
            onMouseOver={this.showDropdown.bind(this, item)}
            data-nav={item.title}
            onFocus={this.showDropdown.bind(this, item)}
            tabIndex={0}
          >
            {item.title}
          </b>
        </a>
        <div className={css.dropdownContent}>{this.state.dropdownToRender !== null ? this.renderDropdown() : null}</div>
      </div>
    )
  }

  renderNewOption = () => {
    return (
      <div className={`${css.dropdown} ${css.blueText}`} onMouseOver={this.hideDropdown} onFocus={this.hideDropdown}>
        <a href="/catalog/new">
          <b>New!</b>
        </a>
      </div>
    )
  }
  renderSaleOption = () => {
    return (
      <div className={`${css.dropdown} ${css.redText}`} onMouseOver={this.hideDropdown} onFocus={this.hideDropdown}>
        <a href="/furniture-sales">
          <b>Sale</b>
        </a>
      </div>
    )
  }
  render() {
    const { showSale, showNew } = this.props.dynamicMenu
    const categories: ICategory[] | undefined = this.props?.links?.[0]?.categories

    return (
      <div role="navigation" aria-label="main navigation" className={css.dynamicMenu} onMouseLeave={this.hideDropdown}>
        {categories && showNew && this.renderNewOption()}
        {categories ? (
          this.props.sortItemsByTreeIndex(categories).map((item: ICategory, index: number) => {
            return this.renderItem(item, index)
          })
        ) : (
          <div />
        )}
        {categories && showSale && this.renderSaleOption()}
      </div>
    )
  }
}
