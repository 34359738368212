import React, { useContext, useEffect, useState } from 'react'

import css from './TopNavigation.scss'
import CategoryMenu from './DynamicMenu/DynamicMenu'
import { IHeader } from './Header'
import { ICartItem } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Cart'
import { IContentBlock } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/OmniPage/interfaces'
import HeaderContentBlocks from './HeaderContentBlocks'
import CartModal, { ICartModalType } from '../Cart/CartModal'
import MorCartContext, { IMorCartContextProviderProps } from '../../Context/MorCartContext'
import { ICategory } from './interfaces'
import MiniCart from './MiniCart/MiniCart'
import { generateProductIdForCartItem } from '../../../Utils/customProductUtils'
import { clearDataLayerEcommerce, onProductRemoveFromCart } from '../../../../utils/dataLayerController'
import { IUnbxdProduct } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/unbxd/unbxdInterfaces'
import { getProductFinalPrice } from '../../../Utils/productUtils'
import { getStoreCode } from '../../../Utils/getStoreCodeFromCookie'
import { SEARCH_INPUT_LABEL } from '../../../../settings/variables'
import LocationContext, { ILocationContextProviderProps } from '../../Context/LocationContext'
import HeaderLocation from './HeaderLocation'
import DesktopSearchBar from './SearchProductInput/DesktopSearchBar'

interface ITopNavigation {
  contentblocks: IContentBlock[]
  dynamicMenu: IDynamicMenu
  handleLocationChange: () => void
  header: IHeader[]
  onSearchChange: (event: any) => void
  sortItemsByTreeIndex: (items: ICategory[]) => ICategory[]
  onMenuOver: () => void
  onMenuBlur: () => void
}
export interface IDynamicMenu {
  showSale: boolean
  storeCode: string
  showNew: boolean
}
const HeaderTopNavigation = (props: ITopNavigation) => {
  const { contentblocks, dynamicMenu, handleLocationChange, header, onSearchChange, sortItemsByTreeIndex } = props
  const { count, methods } = useContext(MorCartContext.Context) as IMorCartContextProviderProps
  const { setLocationByZip } = useContext(LocationContext.Context) as ILocationContextProviderProps
  const [currentCount, setCurrentCount] = useState<number>(count)

  const [showMiniCart, setShowMiniCart] = useState<boolean>(false)
  const [showRetrieveCartModal, setShowRetrieveCartModal] = useState<boolean>(false)
  const [pageScrolled, setPageScrolled] = useState<boolean>(false)

  useEffect(() => {
    const header = document.getElementById('topHeader')
    const scrollFunction = () => {
      if (header) {
        if (window.pageYOffset > header.offsetTop) {
          header.classList.add(css.scrollHeader)
          setPageScrolled(true)
        } else {
          header.classList.remove(css.scrollHeader)
          setPageScrolled(false)
        }
      }
    }
    window.onscroll = scrollFunction
  }, [])

  useEffect(() => {
    const setMorCartSubtotalOnWindow = async () => {
      const data = await loadCart(true)
      let subTotal: number = 0
      if (data?.items && window) {
        for (const cartItem of data?.items) {
          const product = cartItem.product as IUnbxdProduct
          subTotal += getProductFinalPrice(product, getStoreCode()) * cartItem.quantity
        }
        window.MOR_CART_SUBTOTAL = subTotal
      }
    }
    if (currentCount !== count) {
      setMorCartSubtotalOnWindow()
      setCurrentCount(count)
    }
  }, [count])

  const toggleCartView = (evt: React.SyntheticEvent<HTMLElement>) => {
    evt.preventDefault()
    if (!showMiniCart) {
      loadCart()
    }
  }

  const loadCart = async (doNotShowMiniCart?: boolean) => {
    console.log('hitting cart')
    const response = await methods.getItems({ populate: true, miniCart: true })
    if (response.data && !doNotShowMiniCart) {
      setShowMiniCart(true)
    }

    return response.data
  }

  const closeCartView = (evt: React.SyntheticEvent<HTMLElement>) => {
    evt.preventDefault()
    setShowMiniCart(false)
  }

  const removeItem = async (item: ICartItem) => {
    await methods.deleteItemFromCart({
      populate: true,
      productId: generateProductIdForCartItem(item),
    })
    loadCart()
    clearDataLayerEcommerce()
    onProductRemoveFromCart(item)
  }

  const toggleRetrieveCartModal = () => {
    setShowRetrieveCartModal(!showRetrieveCartModal)
  }

  const onCartItemsRetrieved = async (newItems: ICartItem[]) => {
    if (newItems?.[0]?.metaData?.zipCode) {
      // setting location using the zipCode in the first item metadata since it's the same zipCode for every item
      // this zipCode is added in Associate App when saving cart, and it's used when retrieving the cart to show the same prices if the location in website is different.
      await setLocationByZip(newItems[0].metaData.zipCode)
    }
    for (const newItemToAdd of newItems) {
      await methods.updateItemMetadata({
        populate: newItems.indexOf(newItemToAdd) === newItems.length - 1,
        productId: generateProductIdForCartItem(newItemToAdd),
        quantity: newItemToAdd.quantity,
        metaData: newItemToAdd.metaData,
      })
    }
    toggleRetrieveCartModal()
  }

  return (
    <React.Fragment>
      <div id="topHeader" className={css.header}>
        <div role="banner" className={css.contentBlocks}>
          <HeaderContentBlocks headerBanner={contentblocks} />
        </div>

        <div className={css.headerLinksContainer}>
          <div className={css.topBar}>
            <img
              style={{ cursor: 'pointer' }}
              src="/static/mor-logo.png"
              alt="Mor Furniture furniture for less"
              width="136"
              min-height="48"
              data-nav="Homepage"
              onClick={() => (window.location.href = '/')}
            />
            <DesktopSearchBar placeholder={SEARCH_INPUT_LABEL} onSearchChange={onSearchChange} />
            <HeaderLocation onLocationChange={handleLocationChange} />
            <div
              onClick={toggleCartView}
              onKeyPress={toggleCartView}
              className={css.headerIconWrap}
              tabIndex={0}
              role="button"
            >
              <span className={css.iconCartContainer}>
                <img
                  src={'/static/icon-cart.png'}
                  alt="Shopping cart"
                  min-width="20"
                  min-height="20"
                  width="20"
                  height="20"
                  data-nav="cart"
                />
                {count > 0 && <span className={css.cartBadge}>{count}</span>}
              </span>
              <div className={`${css.cartBackground} ${showMiniCart && css.showMiniCart}`} onClick={closeCartView} />
            </div>
          </div>
          <div className={css.categoryMenu} style={{ margin: '0 auto', maxWidth: '1115px' }}>
            <CategoryMenu
              links={header}
              sortItemsByTreeIndex={sortItemsByTreeIndex}
              dynamicMenu={dynamicMenu}
              handleLocationChange={handleLocationChange}
              showOverlay={props.onMenuOver}
              hideOverlay={props.onMenuBlur}
            />
          </div>
        </div>
      </div>
      {showRetrieveCartModal && (
        <CartModal
          type={ICartModalType.RETRIEVE}
          onClose={toggleRetrieveCartModal}
          onCartItemsRetrieved={onCartItemsRetrieved}
        />
      )}
      <MiniCart
        showMiniCart={showMiniCart}
        onClose={closeCartView}
        onRetrieveCartModal={toggleRetrieveCartModal}
        onRemoveItem={removeItem}
        pageScrolled={pageScrolled}
      />
    </React.Fragment>
  )
}

export default HeaderTopNavigation
