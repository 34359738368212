import * as React from 'react'
import { IFooterDataFormat } from '../Footer'
import css from './BaseFooter.scss'
import { Container } from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Layout'
import { SiteMapSubNavItem } from '../SubFooter/SiteMap/SiteMap'
import Router from '../../../../../utils/withContextRoot/Router'
import moment from 'moment'

export interface IProps {
  data: IFooterDataFormat | null
}
export default class BaseFooter extends React.Component<IProps, any> {
  renderError = (): JSX.Element => {
    return <div className={css.baseFooter} />
  }

  renderAboutSite = () => {
    const { data } = this.props
    const onEventHandler = (link: string) => Router.push(`/${link}`)

    if (data && data.siteMap) {
      for (const item of data.siteMap) {
        if (item.title === 'About this Site') {
          return item.children.map((navItem: SiteMapSubNavItem, index: number) => {
            return (
              <div key={index} className={css.topLevel}>
                <span
                  onClick={() => onEventHandler(navItem.link)}
                  onKeyPress={() => onEventHandler(navItem.link)}
                  data-footer-nav={navItem.title}
                  tabIndex={0}
                >
                  {navItem.title}
                </span>
              </div>
            )
          })
        }
      }
    }
    return
  }

  renderCopyright = () => {
    return <p>&copy; {moment().year()} Mor Furniture</p>
  }
  public render() {
    return (
      <div className={css.baseFooter}>
        <Container fluid={false}>
          <div className={css.footerInner}>
            <div className={css.aboutSiteMobile}>{this.renderAboutSite()}</div>
            <div className={css.copyright}>{this.renderCopyright()}</div>
            <div className={css.aboutSiteDesktop}>{this.renderAboutSite()}</div>
          </div>
        </Container>
      </div>
    )
  }
}
