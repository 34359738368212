import * as React from 'react'
import css from './FormGroupRow.scss'

export interface IAppProps {
  children: any
  vCenter?: boolean
  hCenter?: boolean
  h?: string
  v?: string
}

export default class FormGroupRow extends React.Component<IAppProps, any> {
  getGetAdditionalStyles = (): any => {
    const style: any = {}
    const { hCenter, vCenter, h, v } = this.props
    if (hCenter) {
      style.justifyContent = 'center'
    }
    if (vCenter) {
      style.alignItems = 'center'
    }
    if (h) {
      style.justifyContent = h
    }
    if (v) {
      style.alignItems = v
    }
    return style
  }
  render() {
    return (
      <div className={css.flexContainer} style={this.getGetAdditionalStyles()}>
        {this.props.children}
      </div>
    )
  }
}
