import * as React from 'react'
import ThemeWrapper from '../Common/Theme/ThemeWrapper'
import HeaderContainer from './HeaderContainer/HeaderContainer'
import FooterContainer from './FooterContainer/FooterContainer'
import BodyContainer from './BodyContainer/BodyContainer'
import css from './Layout.scss'

export interface ILayoutProps {
  data: {
    header: JSX.Element
    layout: any
    footer: JSX.Element
  }
  configuration: {
    globalStyles: boolean
  }
  class?: any
  style?: any
  url?: string
}

const ApplyThemeWrapper = (props: any) => {
  const {
    children,
    configuration: { globalStyles },
  } = props
  const globalClasses: string = [css.fillHeight, css.fillCore].join(' ')
  if (globalStyles) {
    return <ThemeWrapper className={`${globalClasses}`}>{children}</ThemeWrapper>
  }
  return <div className={`${globalClasses}`}>{children}</div>
}

export default class Layout extends React.Component<ILayoutProps> {
  public render() {
    return (
      <ApplyThemeWrapper {...this.props}>
        <HeaderContainer>{this.props.data.header}</HeaderContainer>
        <BodyContainer>
          <this.props.data.layout url={this.props.url}>{this.props.children}</this.props.data.layout>
        </BodyContainer>
        <FooterContainer>{this.props.data.footer}</FooterContainer>
      </ApplyThemeWrapper>
    )
  }
}