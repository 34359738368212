import * as React from 'react'
import { IHeader } from './Header/Header'
import { IPage } from '../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/OmniPage/interfaces'
import css from './Layout.scss'

export interface ILayoutProps {
  // TODO: create proper interfaces
  header: IHeader[]
  footer: IPage[]
}
/**
 * The core wrapper for all pages
 */
const Layout = (props: any) => {
  const miniheaders: string[] = ['/checkout']
  const homeHeader: string[] = ['/']
  const hasMargin: boolean = miniheaders.indexOf(props.url) === -1 ? true : false
  const noMarginTop: boolean = homeHeader.indexOf(props.url) !== -1 ? true : false

  return (
    <React.Fragment>
      <div className={`${hasMargin ? (noMarginTop ? '' : css.layout) : css.checkoutHeader} cor-app margin-bottom`}>
        {props.children}
      </div>
    </React.Fragment>
  )
}

export default Layout
