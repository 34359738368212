import * as React from 'react'
import Router from 'next/router'
import css from './BurgerMenu.scss'
import { ICategory } from '../../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Catalog'
import LocationContext from '../../../../Context/LocationContext'
import MobileHeader from '../MobileHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'

enum IDirectionMenu {
  BACK = 'back',
  FORWARD = 'forward',
}
interface IBurgerMenuProps {
  items: ICategory[]
  burgerMenu: boolean
  toggleBurgerMenu: Function
  showNew: boolean
  sortItemsByTreeIndex: (items: ICategory[]) => ICategory[]
  showSale?: boolean
  cartCount: number
}
interface IBurgerMenuState {
  selectedArray: any
  currentMenu: ICategory[]
  childMenu: ICategory[]
  menuExpanded: boolean
  direction: IDirectionMenu | null
  topLevelMenu: boolean
  selectedCategory: string
  saleMenuExpanded: boolean
}

export class BurgerMenu extends React.Component<IBurgerMenuProps, IBurgerMenuState> {
  static contextType = LocationContext.Context

  constructor(props: IBurgerMenuProps) {
    super(props)
    this.state = {
      menuExpanded: true,
      selectedArray: [],
      currentMenu: props.items as ICategory[],
      childMenu: props.items as ICategory[],
      direction: null,
      topLevelMenu: true,
      selectedCategory: '',
      saleMenuExpanded: false,
    }
  }

  public closeBurgerMenu = () => {
    const { items, toggleBurgerMenu } = this.props
    this.setState({
      menuExpanded: false,
      selectedArray: [],
      currentMenu: items as ICategory[],
      childMenu: items as ICategory[],
      direction: null,
    })
    toggleBurgerMenu()
  }

  public expandItem = () => {
    const { items } = this.props
    this.setState({ menuExpanded: true, currentMenu: items || ([] as ICategory[]), selectedArray: [] })
  }

  public findParentMenu = (items: ICategory[]) => {
    const { selectedArray } = this.state
    let parentElement: any = []
    for (let i = 0; i < selectedArray.length - 1; i++) {
      parentElement = items.find((e: ICategory) => e.id === selectedArray[i])
    }
    return parentElement
  }

  public onMenuItemClick = (item: ICategory, sale?: boolean) => {
    const { selectedArray, saleMenuExpanded } = this.state
    if (saleMenuExpanded) {
      item.slug === 'sale' ? Router.push(`/sale/sale-view-all`) : Router.push(`/sale/${item.slug}`)
    } else if (item.children && item.children.length) {
      if (item.children[0].title.indexOf('View All') === -1) {
        const categoryItem = {
          title: 'View All',
          slug: item.slug,
        } as ICategory
        item.children.unshift(categoryItem)
      }

      selectedArray.push(item.id)
      if (sale) {
        this.setState({ saleMenuExpanded: true })
      }
      this.setState({
        selectedArray: selectedArray,
        childMenu: item.children,
        direction: IDirectionMenu.FORWARD,
        topLevelMenu: false,
        selectedCategory: item.title,
      })

      setTimeout(() => {
        this.setState({
          direction: null,
          currentMenu: item.children,
        })
      }, 500)
    } else {
      Router.push(`/catalog/${item.slug}/`)
    }
  }

  public backButton = () => {
    const { selectedArray } = this.state
    const { items } = this.props
    if (selectedArray.length == 0) {
      this.setState({
        menuExpanded: false,
        direction: null,
        selectedArray: [],
        topLevelMenu: true,
        saleMenuExpanded: false,
      })
    } else {
      selectedArray.pop()
      this.setState({
        childMenu: items,
        direction: IDirectionMenu.BACK,
        selectedArray,
        topLevelMenu: true,
        saleMenuExpanded: false,
      })
    }
  }

  public renderChildTree = (item: ICategory, index: number, sale?: boolean) => {
    const { saleMenuExpanded } = this.state
    return (
      <div className={css.items} key={index}>
        <div onClick={() => this.onMenuItemClick(item, sale)}>
          <div className={css.specialItem}>
            <span className={css.text} data-nav={item.title}>
              {item.title}
              {item.children && item.children.length && !saleMenuExpanded ? (
                <FontAwesomeIcon icon={faAngleRight} fixedWidth className={css.itemExpand} />
              ) : (
                ''
              )}
            </span>
          </div>
        </div>
      </div>
    )
  }

  renderNewOption = () => {
    return (
      <div className={css.items}>
        <div onClick={() => Router.push('/catalog/new')}>
          <div className={css.specialItem}>
            <span className={css.text} data-nav="New!">
              New!
            </span>
          </div>
        </div>
      </div>
    )
  }

  renderSaleOption = () => {
    if (this.props.showSale) {
      return (
        <div className={css.items}>
          <div onClick={() => Router.push('/furniture-sales')}>
            <div className={css.specialItem}>
              <span className={css.text} data-nav="Sale">
                Sale
              </span>
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  showClosestStore = () => {
    const store = this.context.myClosestStore ? this.context.myClosestStore : null
    return store ? (
      <div className={css.deliveryAreaMessage}>Your delivery area</div>
    ) : (
      <div className={`${css.deliveryAreaMessage} ${css.outOfArea}`}>Out of delivery area</div>
    )
  }

  renderStoreInfo = () => {
    const store = this.context.myClosestStore ? this.context.myClosestStore : null
    const href = store ? `/store/${store.slug}` : `/storelocator`

    return (
      <div className={css.geoDisplay}>
        <FontAwesomeIcon icon={faMapMarkerAlt} fixedWidth className={css.geoIcon} />
        <a href={href}>
          <div>{this.showClosestStore()}</div>
          <b>
            <u>
              {this.context.location.city}, {this.context.location.state}
            </u>
          </b>
        </a>
      </div>
    )
  }

  public render() {
    const { burgerMenu, toggleBurgerMenu } = this.props
    const { menuExpanded, direction, childMenu, currentMenu, selectedCategory } = this.state

    return (
      <React.Fragment>
        <div className={`${css.burgerMenu} ${burgerMenu ? css.activeMenu : ``} hidden-md hidden-lg`}>
          <MobileHeader cartCount={this.props.cartCount} onClose={toggleBurgerMenu} />

          <div
            className={`${css.menu} ${css.shopMenu}`}
            style={menuExpanded ? { transform: 'translate(0) translate(0)', visibility: 'visible' } : {}}
          >
            {this.state.topLevelMenu && this.props.showNew && this.renderNewOption()}

            <div
              className={`${css.childMenu}  ${
                direction && direction === IDirectionMenu.FORWARD
                  ? css.RTLC
                  : direction === IDirectionMenu.BACK
                  ? css.LTRC
                  : ''
              }`}
            >
              {!this.state.topLevelMenu && (
                <div className={css.backButton}>
                  <div className={css.buttonContainer} onClick={() => this.backButton()}>
                    <FontAwesomeIcon icon={faAngleLeft} fixedWidth className={css.backIcon} />
                    Back
                  </div>
                  <span className={css.menuHeadline}>{selectedCategory}</span>
                </div>
              )}
              {childMenu &&
                childMenu.length &&
                this.props.sortItemsByTreeIndex(childMenu).map((item: ICategory, index: number) => {
                  return item.hidden ? '' : this.renderChildTree(item, index)
                })}
              {this.state.topLevelMenu && this.renderSaleOption()}

              <div className={css.locationInfo}>{this.context.location && this.renderStoreInfo()}</div>
              <div className={css.greyBottom}>
                <div className={css.linksContainer}>
                  <a href="/storelocator">Find A Store</a>
                  <a href="/delivery-pickup">Manage Order</a>
                </div>
              </div>
            </div>
            <div
              className={`${css.parentMenu}  ${
                direction && direction === IDirectionMenu.FORWARD
                  ? css.RTL
                  : direction === IDirectionMenu.BACK
                  ? css.LTR
                  : ''
              }`}
            >
              {!this.state.topLevelMenu && (
                <div className={css.backButton}>
                  <div className={css.buttonContainer} onClick={() => this.backButton()}>
                    <FontAwesomeIcon icon={faAngleLeft} fixedWidth className={css.backIcon} />
                    Back
                  </div>
                  <span className={css.menuHeadline}>{selectedCategory}</span>
                </div>
              )}
              {currentMenu &&
                currentMenu.length &&
                this.props.sortItemsByTreeIndex(currentMenu).map((item: ICategory, index: number) => {
                  return item.hidden ? '' : this.renderChildTree(item, index)
                })}
              {this.state.topLevelMenu && this.renderSaleOption()}

              <div className={css.locationInfo}>{this.context.location && this.renderStoreInfo()}</div>
              <div className={css.greyBottom}>
                <div className={css.linksContainer}>
                  <a href="/storelocator" data-nav="Find A Store">
                    Find A Store
                  </a>
                  <a href="/delivery-pickup" data-nav="Manage Order">
                    Manage Order
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
