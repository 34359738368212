import appendQueryString from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/appendQueryString'
import { merchantVisibility, storeAvailability } from '../../../../../settings/variables'
import { AxiosResponse } from 'axios'
import { getUnbxdCatalog } from '../../../../../utils/unbxd/unbxdApiCalls'
import { IStoreLocation } from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Locations/interfaces'
import { DATE_FORMATS, formatDate } from '../../../../Utils/dateFormatter'
import validateAndReturnJson, {
  IVALIDATION_TYPE,
} from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/objectValidator'
import React from 'react'

interface IHours {
  hours: string
  days: string
}

const getOnSaleResponse = async (storeCode: string) => {
  return await getUnbxdResponse(storeCode, `sale_${storeCode}`)
}

const getNewPageResponse = async (storeCode: string) => {
  return await getUnbxdResponse(storeCode, 'new')
}

const getUnbxdResponse = async (storeCode: string, pageQuery: string) => {
  try {
    let url: string = ``
    url = appendQueryString(url, {
      p: pageQuery,
    })
    url = appendQueryString(url, { version: 'V2' })
    url = appendQueryString(url, { pagetype: 'boolean' })
    url = appendQueryString(url, { start: '0' })
    url = appendQueryString(url, { rows: '1' })
    url = appendQueryString(url, { fields: 'uniqueId' })
    url = appendQueryString(url, { filter: merchantVisibility })
    url = appendQueryString(url, { filter: `${storeAvailability}${storeCode}` })
    const targetResponse: AxiosResponse = await getUnbxdCatalog(url)
    const { products } = targetResponse?.data?.response || []

    if (products?.length > 0) {
      return true
    } else {
      return false
    }
  } catch (e) {
    console.log(e?.response?.data || e)
    return false
  }
}

const renderOpenHours = (store: IStoreLocation) => {
  const today = new Date()
  const todayString = formatDate(today, DATE_FORMATS.MMDD)
  let holidayHours = ''
  let normalHours = ''
  if (store.otherHours) {
    const jsonHours: IHours[] = validateAndReturnJson(store.otherHours, IVALIDATION_TYPE.ARRAY) as IHours[]
    jsonHours.map((item: IHours) => {
      if (item.days.includes(todayString)) {
        holidayHours = item.hours
      }
    })
  }

  if (store.normalHours) {
    const jsonHours: IHours[] = validateAndReturnJson(store.normalHours, IVALIDATION_TYPE.ARRAY) as IHours[]
    jsonHours.map((item: IHours) => {
      if (item.days?.substring(0, 3) === today?.toUTCString().substring(0, 3)) {
        normalHours = item.hours
      }
    })
  }

  if (holidayHours) {
    return holidayHours !== 'CLOSED' ? (
      <>
        <div>Open Today From</div>
        <div>{holidayHours}</div>
      </>
    ) : (
      <div>Closed Today</div>
    )
  }

  return normalHours !== 'Closed' ? (
    <>
      <div>Open Today From</div>
      <div>{normalHours}</div>
    </>
  ) : (
    normalHours
  )
}

export { getOnSaleResponse, getNewPageResponse, renderOpenHours }
