import { AxiosResponse } from 'axios'
import { has } from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/lodash'
import { IPage } from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/OmniPage/interfaces'
import { IHeader } from '../../components/Tenant/Layout/Header/Header'
import getDefaultCmsTransport from './cmsInterceptor'

const getDynamicAppContent = async () => {
  let headerNav: IHeader[] | [] = []
  let footerNav: IPage[] = []
  const postBody: any = {
    cacheKey: 'header-footer',
    queries: [
      {
        id: 'header',
        type: 'categorytree',
        method: 'navigation',
        params: [
          {
            title: 'Mor Product Categories',
          },
        ],
      },
      {
        id: 'footer',
        type: 'navigationtree',
        method: 'navigation',
        params: [
          {
            title: 'Mor Footer',
          },
        ],
      },
    ],
  }

  try {
    const combineQueryResponse: AxiosResponse = await getDefaultCmsTransport(true).post('/combine/queries', postBody)
    if (combineQueryResponse.data) {
      const { valid } = combineQueryResponse.data
      const validLookup: { [x: string]: any } = {}
      for (const validItem of valid) {
        validLookup[validItem.meta.id] = validItem.response
      }
      const { header, footer } = validLookup
      if (header) {
        headerNav = header
      }
      if (footer) {
        footerNav = has(footer, [0, 'pages']) || []
      }
    }
  } catch (e) {
    console.log(has(e, ['response', 'data', 'message']) || e.toString())
  }
  return { headerNav, footerNav }
}

export default getDynamicAppContent
