import React from 'react'
import css from './InStockCheckbox.scss'

interface IInStockCheckboxProps {
  onClick: Function
  inStock: boolean
  mobileView?: boolean
}

export const InStockCheckbox = (props: IInStockCheckboxProps) => {
  const { onClick, inStock, mobileView } = props
  return (
    <div className={mobileView ? css.inStockCheckMobile : css.inStockCheck} onClick={() => onClick()}>
      <label>
        <input type="checkbox" checked={inStock} onChange={() => onClick()} />
        In Stock
      </label>
    </div>
  )
}
