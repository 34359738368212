import React, { useState } from 'react'
import {
  IAccount,
  ILoginResponseInterface,
  IForgotPasswordResponse,
  IUserData,
  IAccountResponse,
} from '../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Account'
import { CMS_API_CALLS } from '../Services/CMS'
import { ITransport } from '../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Services/API/CMS'

// -------------------------------CONTEXT------------------------------

/**
 * You can pass in all your user account api calls
 */
export interface IUserAccountMethods {
  login: (identifier: string, password: string, ipAddress?: string) => Promise<ILoginResponseInterface>
  register?: (user: Partial<IUserData>) => Promise<IAccountResponse>
  forgotPassword?: (email: string, url: string) => Promise<IForgotPasswordResponse>
  getUser?: (user: IUserData) => Promise<IAccountResponse>
  updateUser?: (user: IUserData) => Promise<IAccountResponse>
  resetPassword?: (code: string, password: string) => Promise<ILoginResponseInterface>
  logout: () => void
}

export interface IUserAccountContextProviderProps {
  login: (identifier: string, password: string, ipAddress?: string) => Promise<ILoginResponseInterface>
  register?: (user: Partial<IUserData>) => Promise<IAccountResponse>
  forgotPassword?: (email: string, url: string) => Promise<IForgotPasswordResponse>
  getUser?: (user: IUserData) => Promise<IAccountResponse>
  updateUser?: (user: IUserData) => Promise<IAccountResponse>
  resetPassword?: (code: string, password: string) => Promise<ILoginResponseInterface>
  logout: () => void
  account: IAccount | null
}
const UserAccountContext = React.createContext<IUserAccountContextProviderProps | null>(null)

// --------------------------- CLASS --------------------------------

interface IIncomingProps {
  account: IAccount | null
  eventHooksMethod?: (type: string, params: any) => void
  transport: ITransport
  children: any
}

const UserAccountDataProvider = (props: IIncomingProps) => {
  const [account, setAccount] = useState(props.account)

  // ----------------  USER ACCOUNT API METHODS --------------------------
  const register = async (user: Partial<IUserData>) => {
    const response: IAccountResponse = await CMS_API_CALLS.ACCOUNT.register(props.transport, {
      ...user,
    })
    if (!response.error && response.data) {
      setAccount(response.data)

      if (props.eventHooksMethod) {
        props.eventHooksMethod('login', response.data)
      }
    }
    return response
  }

  const forgotPassword = async (email: string, url: string) => {
    const response: IForgotPasswordResponse = await CMS_API_CALLS.ACCOUNT.forgotPassword(props.transport, {
      email,
      url,
    })
    if (!response.error && response.data) {
      if (props.eventHooksMethod) {
        props.eventHooksMethod('forgotPassword', response.data)
      }
    }
    return response
  }

  const resetPassword = async (code: string, password: string) => {
    const response: ILoginResponseInterface = await CMS_API_CALLS.ACCOUNT.resetPassword(props.transport, {
      code,
      password,
      passwordConfirmation: password,
    })
    if (!response.error && response.data) {
      if (props.eventHooksMethod) {
        props.eventHooksMethod('resetPassword', response.data)
      }
    }
    return response
  }

  const getUser = async (user: IUserData) => {
    const response: IAccountResponse = await CMS_API_CALLS.ACCOUNT.getUser(props.transport, user)
    if (!response.error && response.data) {
      if (props.eventHooksMethod) {
        props.eventHooksMethod('getUser', response.data)
      }
    }
    return response
  }

  const updateUser = async (user: IUserData) => {
    const response: IAccountResponse = await CMS_API_CALLS.ACCOUNT.updateUser(props.transport, user)
    if (!response.error && response.data && account) {
      const newAccount: IAccount = { token: account.token, user: response.data }
      setAccount(newAccount)
      if (props.eventHooksMethod) {
        props.eventHooksMethod('updateUser', response.data)
      }
    }
    return response
  }

  const login = async (identifier: string, password: string, ipAddress?: string) => {
    const response: ILoginResponseInterface = await CMS_API_CALLS.ACCOUNT.login(props.transport, {
      identifier,
      password,
      ...(ipAddress && { ipAddress }), // Only add ipAddress to object if its defined
    })
    if (!response.error) {
      setAccount(response.data)
      if (props.eventHooksMethod) {
        props.eventHooksMethod('login', response.data)
      }
    }
    return response
  }

  const logout = async () => {
    setAccount(null)
    if (props.eventHooksMethod) {
      props.eventHooksMethod('logout', null)
    }
  }

  // -------------------  END USER ACCOUNT API METHODS ------------------------

  const { children } = props
  const exportedValues: IUserAccountContextProviderProps = {
    login,
    logout,
    register,
    forgotPassword,
    getUser,
    updateUser,
    resetPassword,
    account,
  }
  return <UserAccountContext.Provider value={exportedValues}>{children}</UserAccountContext.Provider>
}

export default {
  Context: UserAccountContext,
  Consumer: UserAccountContext.Consumer,
  Provider: UserAccountDataProvider,
}
