import * as React from 'react'
import css from './CheckoutHeader.scss'
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface ICheckoutHeaderProps {
  text?: string
  mobileMessage?: string
  link: string
}
class CheckoutHeader extends React.Component<ICheckoutHeaderProps> {
  constructor(props: any) {
    super(props)
  }

  render() {
    const { text, link, mobileMessage } = this.props
    return (
      <div className={`${css.categoryMenu} header`}>
        <div className={css.cartButtonContainer}>
          <div className={css.cartButton}>
            <a href={link || '/cart'}>
              <span className={css.desktopMessage}>
                <FontAwesomeIcon icon={faCaretLeft} className={css.backButton} />
                <span>{text || 'Go back'} </span>
              </span>
              <span className={css.mobileMessage}>
                <FontAwesomeIcon icon={faCaretLeft} className={css.backButton} />
                <span>{mobileMessage || 'Go back'} </span>
              </span>
            </a>
          </div>
        </div>
        <div className={css.logoContainer}>
          <a href="/" className={css.logo}>
            <img
              src={'/static/mor-logo.png'}
              alt="Mor Furniture furniture for less"
              className="large"
              width="136"
              height="48"
              style={{ marginTop: '5px' }}
            />
          </a>
        </div>
      </div>
    )
  }
}

export default CheckoutHeader
