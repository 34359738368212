import React from 'react'
import css from './MobileHeader.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faBars, faTimes, faCreditCard } from '@fortawesome/free-solid-svg-icons'

interface IMobileHeaderProps {
  cartCount: number
  onClickBurgerMenu?: () => void
  onClickLocationMenu?: () => void
  onClose?: any
}

const MobileHeader = (props: IMobileHeaderProps) => {
  const { cartCount, onClickBurgerMenu, onClickLocationMenu, onClose } = props
  return (
    <div className={css.mobileMenu}>
      <div className={css.left}>
        {onClose && (
          <div onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} fixedWidth className={`${css.closeButton} ${css.item}`} />
          </div>
        )}
        {onClickBurgerMenu && (
          <div onClick={onClickBurgerMenu}>
            <FontAwesomeIcon icon={faBars} fixedWidth className={css.item} />
          </div>
        )}
        {onClickLocationMenu && (
          <div onClick={onClickLocationMenu} style={onClose ? { marginTop: '10px' } : {}}>
            <FontAwesomeIcon icon={faMapMarkerAlt} fixedWidth className={css.item} />
          </div>
        )}
      </div>

      <a className={css.item} href="/">
        <img
          src="/static/mor-logo.png"
          alt="Mor Furniture furniture for less"
          className="large"
          width="82"
          height="31"
          min-width="82"
          min-height="31"
        />
      </a>

      <div className={css.itemContainer}>
        <div tabIndex={0} role="button" onClick={() => (window.location.href = '/mor-credit')}>
          <FontAwesomeIcon
            icon={faCreditCard}
            width="20"
            title="Financing page"
            focusable="false"
            role="img"
            aria-label="Financing page"
          />
        </div>
        <a className={`${css.item} ${css.iconCartContainer}`} href="/cart">
          <img
            src={'/static/icon-cart.png'}
            alt="Shopping cart"
            width="20"
            height="20"
            min-width="20"
            max-height="20"
          />
          {cartCount > 0 && <span className={css.cartBadge}>{cartCount}</span>}
        </a>
      </div>
    </div>
  )
}

export default MobileHeader
