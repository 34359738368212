import { getWindow } from '../window/window'

/**
 * Mark/store the CSS as fully loaded in a global variable.
 * @param src URL of the css
 */
const markCssFullyLoaded = (src: string) => {
  const win: Window | null = getWindow()
  if (win) {
    win.cssLoadMap = window.cssLoadMap || []
    win.cssLoadMap[src] = true
  }
}

/**
 * Returns true if the css has been added to the page
 * @param src URL of the css
 */
const isCssAdded = (src: string) => {
  return Boolean(document.querySelector('link[href="' + src + '"]'))
}

/**
 * Returns true if the css has been fully loaded
 * @param src URL of the css
 */
const isCssFullyLoaded = (src: string) => {
  return window.cssLoadMap && src in window.cssLoadMap && window.cssLoadMap[src]
}

/**
 * Load a css.
 * @param src URL of the css
 * @param onLoadCallback Callback function when the css is fully loaded
 * @param onLoadErrorCallback Callback function when the css fails to load
 */
export const loadExternalCss = (
  src: string,
  onLoadCallback?: () => void,
  onLoadErrorCallback?: () => void,
): undefined | null => {
  if (!src) return

  // Check if the css is already loaded
  if (isCssAdded(src)) {
    // If css already loaded successfully, trigger the callback function
    if (isCssFullyLoaded(src) && onLoadCallback) onLoadCallback()
    return
  }

  // Loading the css...
  const style = document.createElement('link')

  style.href = src
  style.type = 'text/css'
  style.rel = 'stylesheet'

  style.onload = () => {
    markCssFullyLoaded(src)

    // Optional callback on css load
    if (onLoadCallback) onLoadCallback()
  }

  style.onerror = () => {
    // Optional callback on css load failure
    if (onLoadErrorCallback) onLoadErrorCallback()
  }

  document.head.append(style)
  return
}
