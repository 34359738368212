import React from 'react'
import css from './LoadingIndicator.scss'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export enum ILoadingIndicatorType {
  PAGE = 'page',
  COMPONENT = 'component',
}

interface IProps {
  type?: ILoadingIndicatorType
  style?: React.CSSProperties
  message?: string
}

const LoadingIndicator = (props: IProps) => {
  return (
    <div className={props.type === ILoadingIndicatorType.PAGE ? `${css.loadingIcon} ${css.centered}` : css.loadingIcon}>
      <FontAwesomeIcon icon={faCircleNotch} className={`${css.rotating} ${css.size}`} style={props.style || {}} />
      {props.message && <div className={css.message}>{props.message}</div>}
    </div>
  )
}

export default LoadingIndicator
