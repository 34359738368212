import React, { useState, useRef, useEffect } from 'react'
import css from './DesktopSearchBar.scss'
import useSetFocus from '../../../../Utils/hooks/useSetFocus'
import { onUnbxdSearch } from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Services/Unbxd/UnbxdController'
import { BROWSE_PAGE_IS_NEW_SESSION } from '../../../../Utils/stateRestoration'
import { UNBXD_AUTOSUGGEST_CSS, UNBXD_AUTOSUGGEST_JS } from '../../../../../settings/variables'
import { loadExternalCss } from '../../../../../utils/loadExternalScript/loadExternalCss'
import Script from 'next/script'
import { encodeAmpersandOnUrl, removeHash } from '../../../../../utils/unbxd/unbxdHelpers'
import { InStockCheckbox } from '../InStockCheckbox/InStockCheckbox'
import { IDynamicOptionsCookie } from '../../../../StoreLocator/interfaces'
import { setCookie } from '../../../../../utils/cookie'

interface IDesktopSearchBarProps {
  onSearchChange: any
  placeholder: string
}

const DesktopSearchBar: React.FC<IDesktopSearchBarProps> = props => {
  const [searchTerm, setSearchTerm] = useState('')
  const [cssLoaded, setCssLoaded] = useState(false)
  const [jsLoaded, setJsLoaded] = useState(false)
  const [inStock, setInStock] = useState(false)

  const { onSearchChange, placeholder } = props

  const inputRef: React.RefObject<HTMLInputElement> = useRef(null)

  useEffect(() => {
    if (!cssLoaded) {
      console.log('loading unbxd autosuggest styles')
      loadExternalCss(UNBXD_AUTOSUGGEST_CSS, () => {
        setCssLoaded(true)
      })
    }
  }, [])

  useSetFocus(inputRef)

  const enterPressed = (event: any) => {
    const code = event.keyCode || event.which
    if (code === 13) {
      sessionStorage.setItem(BROWSE_PAGE_IS_NEW_SESSION, 'true')
      onSearchChange(encodeAmpersandOnUrl(removeHash(event.target.value)))
    }
  }

  const onChangeSearchTerm = (targetValue: string) => {
    setSearchTerm(targetValue)
  }

  const handleSearch = () => {
    if (searchTerm && !/^\s+$/.test(searchTerm)) {
      sessionStorage.setItem(BROWSE_PAGE_IS_NEW_SESSION, 'true')
      onUnbxdSearch(searchTerm)
      location.href = `/search?q=${encodeAmpersandOnUrl(removeHash(searchTerm))}`
    }
  }

  const onChangeInStock = () => {
    setCookie(IDynamicOptionsCookie.IN_STOCK_FILTER, {
      inStockFilter: !inStock,
    })

    setInStock(!inStock)
  }

  return (
    <div className={css.searchInput}>
      {!jsLoaded && (
        <>
          {console.log('loading unbxd autosuggest js')}
          <Script
            src={UNBXD_AUTOSUGGEST_JS}
            onLoad={() => {
              setJsLoaded(true)
            }}
          />
        </>
      )}
      <input
        size={100}
        className={css.input}
        type="text"
        placeholder={placeholder}
        onKeyDown={enterPressed}
        value={searchTerm}
        onChange={e => onChangeSearchTerm(e.target.value)}
        ref={inputRef}
        aria-label={placeholder}
        autoComplete="off"
      />
      <InStockCheckbox inStock={inStock} onClick={onChangeInStock} />
      <div className={css.icon} onClick={handleSearch} onKeyDown={enterPressed} unbxdattr="sq_bt" tabIndex={0}>
        <img src="/static/icon-search.png" alt="Search " />
      </div>
    </div>
  )
}

export default DesktopSearchBar
