import * as React from 'react'
import css from './Footer.scss'
import SubFooter from './SubFooter/SubFooter'
import BaseFooter from './BaseFooter/BaseFooter'
import { SiteMapNavItem } from './SubFooter/SiteMap/SiteMap'
import data from './_mock/data.json'
import { IPage } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/OmniPage/interfaces'

// What we expect out data to look like when it is returned from the server
export interface IFooterDataFormat {
  siteMap: SiteMapNavItem[]
  socialMediaLinks: object[]
  phoneNumber: string
  email: string
  hours: string[]
  address: string
  fastestResponseLabel: string
  fastestResponseNumber: string
}
interface IState {
  loading: boolean
  loadedData: IFooterDataFormat | null
}
export interface IFooterProps {
  footer: IPage[]
}

export default class Footer extends React.Component<IFooterProps, IState> {
  constructor(props: IFooterProps) {
    super(props)
    this.state = {
      loading: true,
      loadedData: null,
    }
  }
  renderErrorInFooter = () => {
    return <footer className={css.footerContainer}>There were issues</footer>
  }
  public render() {
    return (
      <footer className={css.footerContainer}>
        <SubFooter data={data} />
        <BaseFooter data={data} />
      </footer>
    )
  }
}
