import Script from 'next/script'
import React, { useEffect, useState } from 'react'
import { UNBXD_AUTOSUGGEST_CSS, UNBXD_AUTOSUGGEST_JS } from '../../../../../settings/variables'
import { setCookie } from '../../../../../utils/cookie'
import { loadExternalCss } from '../../../../../utils/loadExternalScript/loadExternalCss'
import { IDynamicOptionsCookie } from '../../../../StoreLocator/interfaces'
import { BROWSE_PAGE_IS_NEW_SESSION } from '../../../../Utils/stateRestoration'
import { InStockCheckbox } from '../InStockCheckbox/InStockCheckbox'
import css from './MobileSearchBar.scss'
import { getUnbxdAutosuggestTopResults } from '../../../../../utils/unbxd/unbxdApiCalls'
import { getCanonicalUrl, getProductImageUrl } from '../../../../Utils/productUtils'
import { IUnbxdProduct } from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/unbxd/unbxdInterfaces'

const SearchIcon = '/static/icon-search.png'

declare module 'react' {
  interface HTMLAttributes<T> extends DOMAttributes<T> {
    unbxdattr?: string
  }
}

interface IMobileSearchBarProps {
  placeholder: string
  onSearchChange: any
}

const MobileSearchBar = (props: IMobileSearchBarProps) => {
  const { placeholder, onSearchChange } = props
  const [activeSearch, setActiveSearch] = useState('')
  const [inStock, setInStock] = useState(false)
  const [cssLoaded, setCssLoaded] = useState(false)
  const [jsLoaded, setJsLoaded] = useState(false)
  const [autosuggestTopResults, setAutosuggestTopResults] = useState([])
  const [showTopResults, setShowTopResults] = useState(false)
  const [topResultsPosition, setTopResultsPosition] = useState('0px')
  const [topResultsWidth, setTopResultsWidth] = useState('0px')

  useEffect(() => {
    const checkDisplayStatus = () => {
      const targetDiv = document.querySelector('.unbxd-as-wrapper') as HTMLElement
      if (targetDiv && window.getComputedStyle(targetDiv).display === 'flex') {
        const height = targetDiv.offsetHeight
        const width = targetDiv.offsetWidth

        setTopResultsPosition(height + 36 + 'px')
        setTopResultsWidth(width + 'px')

        setShowTopResults(true)
      } else {
        setShowTopResults(false)
      }
    }

    // Check the display status initially
    checkDisplayStatus()

    // Optionally, add an event listener to monitor changes
    const observer = new MutationObserver(checkDisplayStatus)
    observer.observe(document.body, { attributes: true, childList: true, subtree: true })

    return () => {
      observer.disconnect()
    }
  }, [autosuggestTopResults])

  const activeExternalLibrary = () => {
    if (!cssLoaded && jsLoaded) {
      loadExternalCss(UNBXD_AUTOSUGGEST_CSS, () => {
        console.log('loading mobile unbxd autosuggest css')
        setCssLoaded(true)
      })
    }
  }

  const onSetActiveSearch = async (evt: any) => {
    setActiveSearch(evt.target.value)
    const response = await getUnbxdAutosuggestTopResults(evt.target.value)
    if (response?.data?.response?.products?.length > 0) {
      setAutosuggestTopResults(response.data.response.products)
    }
  }
  const onSearchButtonClick = () => {
    if (onSearchChange) {
      sessionStorage.setItem(BROWSE_PAGE_IS_NEW_SESSION, 'true')
      onSearchChange(activeSearch)
    }
  }
  const enterPressed = (event: any) => {
    const code = event.keyCode || event.which
    if (code === 13) {
      sessionStorage.setItem(BROWSE_PAGE_IS_NEW_SESSION, 'true')
      onSearchButtonClick()
    }
  }

  const onChangeInStock = () => {
    setCookie(IDynamicOptionsCookie.IN_STOCK_FILTER, {
      inStockFilter: !inStock,
    })

    setInStock(!inStock)
  }

  return (
    <div className={css.searchInput}>
      {!jsLoaded && (
        <>
          <Script
            src={UNBXD_AUTOSUGGEST_JS}
            onLoad={() => {
              console.log('loading mobile unbxd autosuggest js')
              setJsLoaded(true)
            }}
            strategy="lazyOnload"
          />
        </>
      )}
      <div className={css.icon} onClick={onSearchButtonClick} onKeyDown={enterPressed} unbxdattr="sq_bt" tabIndex={0}>
        <img src={SearchIcon} alt="Search " />
      </div>
      <input
        style={{ fontSize: '16px' }} // Avoid zooming on mobile view.
        className={css.input}
        id="searchInput"
        type="text"
        autoComplete="off"
        unbxdattr="sq"
        value={activeSearch}
        placeholder={placeholder}
        onChange={onSetActiveSearch}
        onKeyDown={enterPressed}
        aria-label="Search"
        onFocus={activeExternalLibrary}
      />
      <InStockCheckbox inStock={inStock} onClick={onChangeInStock} mobileView />
      {activeSearch && autosuggestTopResults.length > 0 && showTopResults && (
        <div className={css.sideContent} style={{ top: topResultsPosition, width: topResultsWidth }}>
          <li className={css.unbxdHeader}>TOP PRODUCTS IN {activeSearch}</li>
          {autosuggestTopResults.slice(0, 4).map((product: IUnbxdProduct) => {
            return (
              <li
                className={`${css.asPopularProduct} `}
                data-value={product.autosuggest}
                data-index="0"
                data-type={product.doctype}
                data-pid=""
                data-src=""
              >
                <div className={css.asPopularProductInfo}>
                  <div className={css.asPopularProductImageContainer}>
                    <img
                      src={getProductImageUrl(product)}
                      alt={product.autosuggest}
                      data-uw-rm-alt-original={product.autosuggest}
                      data-uw-rm-alt="ALT"
                    />
                  </div>
                  <div className={css.asPopularProductImageName} style={{ minHeight: '42px' }}>
                    <a
                      href={(product?.variants?.[0] as IUnbxdProduct)?.productUrl}
                      target="_blank"
                      data-uw-rm-brl="PR"
                      data-uw-original-href={getCanonicalUrl(product)}
                      aria-label={`${product.autosuggest} - open in a new tab`}
                      data-uw-rm-ext-link="na"
                      uw-rm-external-link-id="https://www.morfurniture.com/product/bloom0000-gry-rvs-pull-schs-bloom-reversible-sofa-chaise-in-gray/$bloomreversiblesofachaiseingray"
                    >
                      {product.autosuggest}
                    </a>
                  </div>
                </div>
              </li>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default MobileSearchBar
