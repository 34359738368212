import * as React from 'react'
import { Field, FormikErrors } from 'formik'
import css from './InputField.scss'
import InputError from './InputError'

export interface IProps {
  name: string
  type: string
  values: any
  errors: FormikErrors<any>
  required?: boolean
  touched: any
  label?: string
  placeholder?: string
  showErrors: boolean
  ariaLabel?: string
}

export default function InputField(props: IProps) {
  const { type, placeholder, ariaLabel, label, name, touched, errors, showErrors, required } = props
  const hasError = touched[name] && errors[name]
  return (
    <div className={css.formGroup}>
      {label && <label htmlFor={name}>{label}:</label>}
      <Field
        aria-label={ariaLabel}
        type={type}
        name={name}
        id={name}
        label={name}
        required={required}
        placeholder={placeholder}
        className={[hasError ? css.error : '', css.input].join(' ')}
      />
      {showErrors && <InputError {...props} name={name} />}
    </div>
  )
}
