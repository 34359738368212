import * as React from 'react'
import css from '../Layout.scss'

export default class Body extends React.Component<any> {
  public render() {
    return (
      <div className={`${css.fillBody}`}>
        <main>{this.props.children}</main>
      </div>
    )
  }
}
