import * as React from 'react'
// core components
import { IFooterDataFormat } from '../Footer'
import SiteMap from './SiteMap/SiteMap'
import MetaData from './MetaData/MetaData'
import Loader from '../../../../Common/Loader'
// helper components
import css from './SubFooter.scss'
import { Container } from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Layout'
import KnowMor from '../KnowMor/KnowMor'

export interface IProps {
  data: IFooterDataFormat | null
}
export default class SubFooter extends React.Component<IProps, any> {
  public render() {
    const { data } = this.props
    if (!data) {
      return <Loader center />
    }
    return (
      <Container fluid={false}>
        <React.Fragment>
          <div className={css.subfooter}>
            <div className={css.navigation}>
              <div className={css.metaData}>
                <MetaData data={data} />
              </div>
              <div className={css.siteMap}>
                <SiteMap siteMap={data.siteMap} />
              </div>
              <div className={css.knowMor}>
                <KnowMor />
              </div>
            </div>
          </div>
        </React.Fragment>
      </Container>
    )
  }
}
