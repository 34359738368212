import * as React from 'react'
import css from './MinifiedFooter.scss'
import { THEME_WRAPPER_TAGS } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Theme/ThemeWrapper'
import moment from 'moment'

const MorIcon = '/static/mor-footer.png'

export const MinifiedFooter: React.FC<any> = () => (
  <div className={`${css.minifiedFooter} flex-center footer ${THEME_WRAPPER_TAGS.PRIMARY_BACKGROUND_COLOR}`}>
    <div className={`${css.mediaIcons} flex-center`}>
      <img alt={'footer'} className={css.logoIcon} src={MorIcon} />
    </div>
    <div className={css.footerMessage}>
      <a target="_blank" href="/terms-conditions">
        Terms & Conditions
      </a>
      <div className={css.footerMessageSecondLine}>
        <a className={css.privacy} target="_blank" href="/privacy-policy">
          Privacy Policy
        </a>
        <span>&#169; {moment().year()} Mor Furniture for Less, Inc</span>
      </div>
    </div>
  </div>
)
