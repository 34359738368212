import React from 'react'
import { config } from '@fortawesome/fontawesome-svg-core'

import App, { AppContext } from 'next/app'
import Head from 'next/head'
import Router from 'next/router'
import { NextPageContext, NextComponentType } from 'next'

// interceptors --------------
import getDefaultCmsTransport from '../src/services/api/cmsInterceptor'
import { getCookie, setCookie } from '../src/utils/cookie'

// core components ----------
import UserAccountContext from '../src/components/Tenant/Context/UserAccountContext'

import { IAccount } from '../src/components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Account'

// layout ---------------------
import GlobalLayout from '../src/components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Layout/Layout'
import Header from '../src/components/Tenant/Layout/Header'
import Footer from '../src/components/Tenant/Layout/Footer'
import Layout from '../src/components/Tenant/Layout/Layout'
import LocationContext from '../src/components/Tenant/Context/LocationContext'

import { CART_COUNT_COOKIE_NAME, SKIP_DYNAMIC_REDIRECTS } from '../src/settings/variables'
import MorCartContext, { IExtraFees } from '../src/components/Tenant/Context/MorCartContext'
import { IHeader } from '../src/components/Tenant/Layout/Header/Header'
import {
  IPage,
  IContentBlock,
} from '../src/components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/OmniPage/interfaces'
import {
  getContentBlocksByTag,
  filterContentBlocksByDate,
  filterContentBlocksByLocation,
} from '../src/components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Services/API/CMS/content-blocks'
import {
  ILocation,
  ILatLng,
} from '../src/components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/interfaces'
import { ILocationCookieType } from '../src/components/StoreLocator/interfaces'
import isBot from '../src/components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/userAgent'
import { IStoreLocation } from '../src/components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Locations/interfaces'
import { CMS_API_CALLS } from '../src/components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Services/API/CMS'

import { IStoreCodes } from '../src/components/Tenant/Services/GERS/Pricing/storelocator'

import '@fortawesome/fontawesome-svg-core/styles.css'
import { getMorCartTotal } from '../src/utils/cart/cartUtils'
import getDynamicAppContent from '../src/services/api/getDynamicAppContent'
import {
  checkRedirects,
  findProp,
  generateAccount,
  generateAnonId,
  getLatLng,
  getLocation,
  getLocationUS,
  getStoreList,
  isABot,
  onCartEventHook,
  prepareAccount,
  setStoreCodesAndLocation,
} from '../src/services/api/appUtils'
import { SignifydScriptProvider } from '@peakactivity/signifyd-connector'

// Import the CSS
config.autoAddCss = false // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

interface IContentBlocksResponse {
  error: string
  data: IContentBlock[] | any
}

const uncachedTransport = getDefaultCmsTransport(false)
const cachedTransport = getDefaultCmsTransport(true)

interface INextPageProps {
  extraFees?: IExtraFees[]
}

interface IState {
  geoLocation: ILocation
}

interface IAppProps {
  Component: NextComponentType<NextPageContext>
  pageProps: INextPageProps
  geoLocation: ILocation
  header: IHeader[]
  footer: IPage[]
  headerContentBlocks: IContentBlock[]
  url: string
  storeList: IStoreLocation[]
  isBot: boolean
  [x: string]: any
  csrfToken: string
}

class MyApp extends App<IAppProps, IState> {
  state = { activeCustomerId: null, myClosestStore: undefined, geoLocation: null, reRender: false }
  timer: any
  static getInitialProps = async (data: AppContext) => {
    const { ctx, Component } = data

    if (isBot(ctx)) {
      return {
        isBot: true,
      } as any
    }
    let pageProps: INextPageProps = {}
    const anonId: string | undefined = getCookie('anonId', ctx.req) // for anon users

    if (!SKIP_DYNAMIC_REDIRECTS) {
      await checkRedirects(ctx)
    }

    const initialCartCount: string | undefined = getCookie(CART_COUNT_COOKIE_NAME, ctx.req) // cache the cart count locally
    // --------------------------------------------- GEOLOCATION -----------------------------------------------------------------
    let geoLocation: ILocation | null = null
    const existingLocationCookieString: string | undefined = getCookie(ILocationCookieType.GEOLOCATION, ctx.req)
    if (existingLocationCookieString) {
      geoLocation = JSON.parse(unescape(existingLocationCookieString)) as ILocation
    }

    const storeList: IStoreLocation[] = await getStoreList()

    // ------------------------------------- get header and footer in one api call -----------------------
    const { headerNav, footerNav } = await getDynamicAppContent()
    // ------------------------------------- get contentblocks -------------------------------------------
    let contentBlocksArray: IContentBlock[] | null = null
    const contentBlocksResponse: IContentBlocksResponse = await getContentBlocksByTag(
      cachedTransport,
      'global-top-banner',
    )
    if (contentBlocksResponse.data) {
      const filteredByDate: IContentBlock[] = filterContentBlocksByDate(contentBlocksResponse.data)
      contentBlocksArray = filterContentBlocksByLocation(filteredByDate, geoLocation)
    }

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps({ ctx } as any)
    }

    const url: string = ctx.req?.url || ''

    return {
      pageProps,
      header: headerNav,
      initialCartCount: initialCartCount !== undefined ? parseInt(initialCartCount, 10) : 0,
      footer: footerNav,
      geoLocation,
      anonId,
      headerContentBlocks: contentBlocksArray,
      url,
      storeList,
      isBot: false,
      csrfToken: (ctx.req as any)?.csrfToken(),
    }
  }

  constructor(props: any) {
    super(props)
    this.state = {
      activeCustomerId: props.activeCustomerId,
      myClosestStore: undefined,
      geoLocation: props.geoLocation,
      reRender: false,
    }
  }

  async componentDidMount() {
    Router.beforePopState((info: any) => {
      location.href = info.as
      return true
    })
    let geoLocation: ILocation | null = null
    const existingLocationCookieString: string | undefined = getCookie(ILocationCookieType.GEOLOCATION, null)
    if (existingLocationCookieString) {
      geoLocation = JSON.parse(unescape(existingLocationCookieString)) as ILocation
    } else {
      try {
        if (!isABot()) {
          const latLng: ILatLng = await getLatLng()
          let location: { [x: string]: any } = await getLocationUS(latLng)
          const state: string = findProp('administrative_area_level_1', location.results)
          const city: string = findProp('locality', location.results)
          const zip: string = findProp('postal_code', location.results)
          geoLocation = {
            state,
            city,
            zip,
            latLng,
          }
          if (!state.length && !city.length && !zip.length) {
            location = await getLocation(latLng)
            const state: string = findProp('administrative_area_level_1', location.results)
            const city: string = findProp('locality', location.results)
            const zip: string = findProp('postal_code', location.results)
            geoLocation = {
              state,
              city,
              zip,
              latLng,
            }
          }
          setCookie(ILocationCookieType.GEOLOCATION, geoLocation)
          this.setState({ geoLocation: geoLocation })
        }
      } catch (e) {
        console.error(`An error occurred while getting locations: ${e.toString()}`)
      }
    }

    await setStoreCodesAndLocation(geoLocation, this.setClosestStore, this.updateGeoLocationInStateAndCookie)

    // Open live chat if the user is on that page.
    if (window?.location?.pathname?.includes('live-chat')) {
      this.timer = setTimeout(() => {
        if (window.PodiumWebChat && window.PodiumWebChat.open) {
          window.PodiumWebChat.open()
        }
      }, 3000)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  updateGeoLocationInStateAndCookie = (geoLocation: ILocation) => {
    setCookie(ILocationCookieType.GEOLOCATION, geoLocation)
    this.setState({ geoLocation: geoLocation })
  }

  setClosestStore = async (codes: IStoreCodes) => {
    const locationResponse: any = await CMS_API_CALLS.LOCATIONS.getSingleLocation(
      getDefaultCmsTransport(true),
      codes.storeCode,
    )
    if (locationResponse.data) {
      if (locationResponse.data[0].legacyStoreId !== 'XX') {
        this.setState({ myClosestStore: locationResponse.data[0] })
      } else {
        this.setState({ myClosestStore: null })
      }
    }
  }

  // Force re render to update home page banner on location change.
  handleLocationChange = () => {
    this.setState({
      reRender: true,
    })
  }

  // ------------------------------------------------------------ RENDER ----------------------------------------------------
  render() {
    const { Component, pageProps, header, footer, headerContentBlocks, url, storeList, isBot, csrfToken } = this.props

    if (isBot) {
      return <div />
    }

    const account: IAccount | null = prepareAccount(this.props)
    const anonId = generateAnonId(this.props)

    return (
      <>
        <Head>
          <title>Mor Furniture</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="csrf-token" content={csrfToken} />
        </Head>
        <SignifydScriptProvider>
          <UserAccountContext.Provider account={account} transport={uncachedTransport}>
            <UserAccountContext.Consumer>
              {(contextProps: any | null) => {
                return (
                  <LocationContext.Provider
                    myClosestStore={this.state.myClosestStore}
                    location={this.state.geoLocation}
                    storeList={storeList as IStoreLocation[]}
                  >
                    <MorCartContext.Provider
                      getCartTotal={getMorCartTotal}
                      account={generateAccount(contextProps.account, null)}
                      transport={uncachedTransport}
                      eventHooksMethod={onCartEventHook}
                      configuration={{
                        preventCartPopulationUrls: ['/cart', '/cart/'],
                      }}
                      anonId={anonId}
                    >
                      <GlobalLayout
                        url={url}
                        configuration={{
                          globalStyles: true,
                        }}
                        data={{
                          header: (
                            <Header
                              pageProps={pageProps}
                              data={header}
                              contentblocks={headerContentBlocks}
                              account={contextProps.account}
                              onChangeLocation={this.handleLocationChange}
                            />
                          ),
                          layout: Layout,
                          footer: <Footer pageProps={pageProps} data={footer} />,
                        }}
                      >
                        <Component {...pageProps} />
                      </GlobalLayout>
                    </MorCartContext.Provider>
                  </LocationContext.Provider>
                )
              }}
            </UserAccountContext.Consumer>
          </UserAccountContext.Provider>
        </SignifydScriptProvider>
      </>
    )
  }
}

export default MyApp
