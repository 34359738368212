import Router from 'next/router'
import withContextRoot from '.'

const push = (link: string) => {
  Router.push(withContextRoot(link))
}

export default {
  push,
}
