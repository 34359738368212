import * as React from 'react'
import css from './LocationDropdown.scss'
import LocationContext from '../../../../Context/LocationContext'
import { IStoreLocation } from '../../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Locations/interfaces'
import LocationByZipCodeInput from '../../LocationByZipCodeInput/LocationByZipCodeInput'
import { renderOpenHours } from '../../DynamicMenu/menuUtils'
import MobileHeader from '../MobileHeader'

interface IBurgerMenuProps {
  menuOpen: boolean
  toggleLocationDropdown: Function
  cartCount: number
  onChangeLocation: () => void
}
interface IBurgerMenuState {
  zipCode: string
  storeCode: string
}

export class LocationDropdown extends React.Component<IBurgerMenuProps, IBurgerMenuState> {
  static contextType = LocationContext.Context

  constructor(props: IBurgerMenuProps) {
    super(props)
    this.state = {
      zipCode: '',
      storeCode: 'XX',
    }
  }

  public closeLocationDropdown = () => {
    const { toggleLocationDropdown } = this.props
    toggleLocationDropdown()
  }

  public zipUpdated = (value: string) => {
    this.setState({ zipCode: value })
  }

  public changeUserLocation = async (evt: any) => {
    evt.preventDefault()
    await this.context.setLocationByZip(this.state.zipCode)
    this.setState({ zipCode: '' })
    this.props.onChangeLocation()
  }

  renderClosestStoreData = (store: IStoreLocation) => {
    return (
      <div onClick={() => (window.location.href = `/store/${store.slug}`)}>
        <div className={css.closestStoreName}>{store.name}</div>
        <div className={css.closestStoreDetail}>{store.streetAddress1}</div>
        <div className={css.closestStoreDetail}>{`${store.city}, ${store.state} ${store.zip}`}</div>
        <div className={css.closestStoreDetail}>{store.phone}</div>
        <div className={css.closestStoreOpenTill}>{renderOpenHours(store)}</div>
      </div>
    )
  }

  showClosestStore = () => {
    const store = this.context.myClosestStore ? this.context.myClosestStore : null
    return store ? (
      this.renderClosestStoreData(store)
    ) : (
      <div className={css.closestStoreMessage}>Outside of delivery area.</div>
    )
  }

  public render() {
    const { menuOpen, toggleLocationDropdown } = this.props

    return (
      <React.Fragment>
        <div className={`${css.locationMenu} ${menuOpen ? css.activeMenu : ``} hidden-md hidden-lg`}>
          <MobileHeader cartCount={this.props.cartCount} onClose={toggleLocationDropdown} />
          <div className={`${css.menu} ${css.firstLevelMenu}`}>
            <div className={css.top}>
              <div className={css.geoHeader}>Change Your Location</div>
              {menuOpen && (
                <LocationByZipCodeInput
                  value={this.state.zipCode}
                  onSetZipCode={this.zipUpdated}
                  onChangeUserLocation={this.changeUserLocation}
                  isMobile={true}
                />
              )}
              <div className={css.geoFooter}>Closest Store to ZIP</div>
              <div>{this.showClosestStore()}</div>
              <a className={css.findAStoreButton} title="Find a store" href="/storelocator">
                FIND A STORE
              </a>
            </div>
            <div className={css.bottom}>
              <a href="/storelocator">Find A Store</a>
              <a href="/delivery-pickup">Manage Order</a>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
