import * as React from 'react'
import { Formik, Form, FormikProps } from 'formik'
import * as Yup from 'yup'
import Cookies from 'js-cookie'
import { InputField, InputError } from '../../../../../Common/FormUtils'
import css from './SignUpForm.scss'
import ThemedButton from '../../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Button/Button'
import { isEmpty } from '../../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/lodash'
import getDefaultTransport from '../../../../../../services/api/defaultInterceptor'
import { KLAVIYO_API_CALLS } from '../../../../../../utils/klaviyo/klaviyoApiCalls'
interface IState {
  email: string
  formFeedbackMessage: string
  errorMessage: boolean
}

export default class DisplayForm extends React.Component<any, IState> {
  constructor(props: any) {
    super(props)
    this.state = { formFeedbackMessage: '', email: '', errorMessage: false }
  }

  validation = Yup.object().shape({
    email: Yup.string()
      .required('Please enter a valid email address')
      .email('Please enter a valid email address.'),
  })

  renderForm = (data: FormikProps<{ email: string }>) => {
    return (
      <React.Fragment>
        <Form className={css.input}>
          <input type="hidden" name="_csrf" value={Cookies.get('_csrf')} />
          <InputField
            {...data}
            name={'email'}
            type={'text'}
            placeholder="Your Email Address"
            required={true}
            showErrors={false}
            ariaLabel="Your Email Address"
          />
          <ThemedButton className={css.submitButton} onClick={() => this.onSubmit(data)}>
            SUBMIT
          </ThemedButton>
          <InputError {...data} name="email" />
          <div className={!this.state.errorMessage ? css.successMessage : css.errorMessage}>
            {this.state.formFeedbackMessage}
          </div>
        </Form>
      </React.Fragment>
    )
  }

  onSubmit = async (values: any) => {
    const hasErrors = !isEmpty(values.errors) || values?.values?.email === '' || values?.values?.email === undefined
    if (hasErrors) {
      return
    }

    try {
      const { email } = values.values
      await KLAVIYO_API_CALLS.onKlaviyoIdentifyByEmail(email)
      const response = await getDefaultTransport().post('/klaviyo-subscribe-to-list', {
        email,
      })

      if (response.data === 'Accepted') {
        this.setState({ formFeedbackMessage: 'You have been added to our email list.', errorMessage: false })
        values.resetForm()
      }
    } catch (e) {
      this.setState({
        formFeedbackMessage: 'There was an error subscribing to our email list.',
        errorMessage: true,
      })
    }
  }
  public render() {
    return (
      <React.Fragment>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={this.validation}
          onSubmit={this.onSubmit}
          render={this.renderForm}
        />
      </React.Fragment>
    )
  }
}
