import * as React from 'react'
import css from './KnowMor.scss'
import SignUpForm from './SignUpForm/SignUpForm'

export default class KnowMor extends React.Component<any> {
  public render() {
    return (
      <div className={css.knowMorContainer}>
        <div className={css.title}>Know Mor</div>
        <div className={css.knowMorText}>
          <p>Join our email list to receive special offers, events, new collection announcements & more.</p>
          <div className={css.privacyPolicy}>
            <a href="/privacy-policy" data-footer-nav="Privacy Policy">
              Privacy Policy
            </a>
          </div>
        </div>

        <SignUpForm />
      </div>
    )
  }
}
