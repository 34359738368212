import { IContentBlock } from '../../../../OmniPage/interfaces'
import { showContentBasedOnCurrentLocation } from '../../../../OmniPage/Utils/geoTag'
import { ILocation } from '../../../interfaces'
import CACHE_CONTSTANT from '../../../Utils/cache/constants'
import { ITransport } from '.'

export const filterContentBlocksByDate = (contentBlocks: IContentBlock[]) => {
  const currDate: number = Date.now()
  const filteredByDate: IContentBlock[] = contentBlocks.filter(block => {
    let doShowBlock: boolean = true
    if (block.publishToday === 'Scheduled') {
      doShowBlock = currDate > Date.parse(String(block.startDate)) && currDate < Date.parse(String(block.endDate))
    }
    return doShowBlock
  })
  return filteredByDate
}

export const filterContentBlocksByLocation = (contentBlocks: IContentBlock[], location: ILocation | null) => {
  const filteredByLocation: IContentBlock[] = contentBlocks.filter(block => {
    let doShowBlock: boolean = true
    if (block.geotaggingOptions && block.geotaggingOptions.enabled) {
      if (location) {
        doShowBlock = showContentBasedOnCurrentLocation(location, block.geotaggingOptions)
      }
    }
    return doShowBlock
  })
  return filteredByLocation
}

export const getContentBlocksByTag = async (transport: ITransport, tag: string) => {
  try {
    const response = await transport.get(`/contentblocks?tag=${tag}`, {
      headers: { cacheKey: `${CACHE_CONTSTANT.FRONTEND.CONTENT.CONTENT_BLOCK}-tag-${tag}` },
    })
    const sortedArray = response.data.sort((a: IContentBlock, b: IContentBlock) => {
      return a.order - b.order
    })
    return {
      error: '',
      data: sortedArray,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

export const getContentBlocksById = async (transport: ITransport, blockId: string) => {
  try {
    const response = await transport.get(`/contentblocks?blockId=${blockId}`, {
      headers: { cacheKey: `${CACHE_CONTSTANT.FRONTEND.CONTENT.CONTENT_BLOCK}-block-${blockId}` },
    })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}
