import React, { useRef, useState } from 'react'
import useSetFocus from '../../../../Utils/hooks/useSetFocus'
import css from './LocationByZipCodeInput.scss'

interface ILocationByZipCodeInput {
  value: string
  onSetZipCode: (value: string) => void
  onChangeUserLocation: (evt?: any) => Promise<void> | void
  isMobile?: boolean
  buttonLabel?: string
  containerClass?: string
  inputClass?: string
  buttonClass?: string
  placeholder?: string
  validateInput?: boolean
  noButton?: boolean
}

const LocationByZipCodeInput: React.FC<ILocationByZipCodeInput> = props => {
  const {
    value,
    onSetZipCode,
    onChangeUserLocation,
    isMobile,
    buttonLabel,
    containerClass,
    inputClass,
    buttonClass,
    placeholder,
    validateInput,
    noButton,
  } = props

  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const buttonTitle = buttonLabel || 'GO'

  const inputRef: React.RefObject<HTMLInputElement> = useRef(null)

  useSetFocus(inputRef)

  const handleUpdateZip = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (/^[0-9]*$/.test(evt.target.value)) {
      setShowErrorMessage(false)
      onSetZipCode(evt.target.value)
    }
  }

  const handleKeyPress = (event: any) => {
    const code = event.keyCode || event.which
    if (code === 13) {
      handleInput(event)
    }
  }

  const handleInput = (event: any) => {
    if (validateInput && value?.length < 5) {
      setShowErrorMessage(true)
    } else {
      onChangeUserLocation(event)
    }
  }

  return (
    <div className={containerClass || css.inputContainer}>
      <input
        placeholder={placeholder || 'Enter Zip Code'}
        type="text"
        value={value}
        style={inputClass ? {} : { width: '100%' }}
        className={inputClass}
        onChange={handleUpdateZip}
        onKeyPress={handleKeyPress}
        ref={inputRef}
        aria-label={'Zip Code'}
        maxLength={5}
      />
      {!noButton && (
        <a
          title={buttonTitle}
          onClick={handleInput}
          onKeyPress={handleInput}
          className={buttonClass}
          style={isMobile ? { marginLeft: '10px' } : {}}
          tabIndex={0}
        >
          {buttonTitle}
        </a>
      )}
      {showErrorMessage && <p className={css.errorMessage}>Please enter a valid 5 digit zip code.</p>}
    </div>
  )
}

export default LocationByZipCodeInput
