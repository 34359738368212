export enum IVALIDATION_TYPE {
  OBJECT = 'OBJECT',
  ARRAY = 'ARRAY',
}

const validateObject = (obj: object, type: IVALIDATION_TYPE) => {
  if (type === IVALIDATION_TYPE.ARRAY && !Array.isArray(obj)) {
    console.error('The data should be an array, but got an object')
    return []
  } else if (type === IVALIDATION_TYPE.OBJECT && Array.isArray(obj)) {
    console.error('The data should be an object, but got an array')
    return {}
  }
  return obj
}

// valid string or object
const validateAndReturnJson = (data: string | object | object[], type: IVALIDATION_TYPE) => {
  try {
    if (typeof data === 'string') {
      const obj: object[] | object = JSON.parse(data)
      return validateObject(obj, type)
    } else if (typeof data === 'object') {
      return validateObject(data, type)
    } else {
      console.error(`Data needs to be either an object or array, got '${typeof data}' instead`)
    }
  } catch (e) {
    console.error(e.toString())
    return type === IVALIDATION_TYPE.ARRAY ? [] : {}
  }
  return type === IVALIDATION_TYPE.ARRAY ? [] : {}
}

export default validateAndReturnJson
