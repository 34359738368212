import * as React from 'react'
import css from './SiteMap.scss'

export interface IAppProps {
  siteMap: SiteMapNavItem[]
}
export interface SiteMapNavItem {
  title: string
  children: SiteMapSubNavItem[]
}
export interface SiteMapSubNavItem {
  title: string
  link: string
  img?: string
}

export default class SiteMap extends React.Component<IAppProps, any> {
  renderNavItem = (item: SiteMapSubNavItem, index: number) => {
    return (
      <div className={css.subNavItem} key={`${item.title}-${index}`}>
        <a href={`/${item.link}`} data-footer-nav={item.title}>
          {item.title}
        </a>
        {item.img && (
          <img
            style={{ height: '20px', margin: 0, float: 'left', marginRight: '10px' }}
            src={item.img}
            alt={item.title}
            min-width="28"
            min-height="28"
          />
        )}
      </div>
    )
  }
  renderColumnSubNavigation = (children: SiteMapSubNavItem[]) => {
    return children.map((item: SiteMapSubNavItem, index: number) => {
      return this.renderNavItem(item, index)
    })
  }
  renderNavColumn = (navItem: SiteMapNavItem, index: number) => {
    return (
      <div key={index} className={[css.column].join(' ')}>
        <div className={css.navTitle}>{navItem.title}</div>
        {this.renderColumnSubNavigation(navItem.children)}
      </div>
    )
  }
  renderNavigation = () => {
    const { siteMap } = this.props
    if (!siteMap) {
      return <div />
    }
    return siteMap.map((navItem: SiteMapNavItem, index: number) => {
      if (navItem.title !== 'About this Site') {
        return this.renderNavColumn(navItem, index)
      }
      return
    })
  }
  public render() {
    return <div className={css.siteMap}>{this.renderNavigation()}</div>
  }
}
