import * as React from 'react'
import css from './TopNavigation.scss'
import { IContentBlock } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/OmniPage/interfaces'

interface IHeaderContentBlockProps {
  headerBanner: IContentBlock[] | undefined
}

const HeaderContentBlocks = (props: IHeaderContentBlockProps) => {
  const renderContentBlocks = () => {
    const { headerBanner } = props
    if (!headerBanner || !headerBanner.length) {
      return null
    }

    return (
      <div className={css.contentBlocksBanner}>
        <div className={css.innerBanner}>
          {headerBanner.map((_item: IContentBlock, index: number) => {
            const attributeContent =
              headerBanner.length && headerBanner[index].content ? headerBanner[index].content.html : ''
            const blockClass = headerBanner.length === 1 ? '' : `descriptionAttribute_${headerBanner.length}_${index}`

            return (
              <span
                key={index}
                className={`${css[blockClass]} ${css.descriptionAttribute}`}
                dangerouslySetInnerHTML={{
                  __html: attributeContent,
                }}
              />
            )
          })}
          <span className={css.bannerItem}>
            <p>
              <span>
                <a title="Find a Store" data-nav="Find a Store" href="/storelocator">
                  Find a store
                </a>
              </span>
            </p>
          </span>
          <span className={css.bannerItemWithSeparator}>
            <p>
              <span>
                <a title="Manage Order" data-nav="Manage Order" href="/delivery-pickup">
                  Manage Order
                </a>
              </span>
            </p>
          </span>
          <span className={css.bannerItemWithSeparator}>
            <p>
              <span>
                <a title="Financing" data-nav="Financing" href="/mor-credit-furniture-financing">
                  Financing
                </a>
              </span>
            </p>
          </span>
        </div>
      </div>
    )
  }

  return <div>{renderContentBlocks()}</div>
}

export default HeaderContentBlocks
