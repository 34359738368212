import React, { useState, useContext } from 'react'
import css from './HeaderLocation.scss'
import LocationContext, { ILocationContextProviderProps } from '../../Context/LocationContext'
import { IStoreLocation } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Locations/interfaces'
import LocationByZipCodeInput from './LocationByZipCodeInput/LocationByZipCodeInput'
import { renderOpenHours } from './DynamicMenu/menuUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

interface IProps {
  onLocationChange: () => void
}

const HeaderLocation = (props: IProps) => {
  const { location, setLocationByZip, myClosestStore, showLocationSelect, setShowLocationSelect } = useContext(
    LocationContext.Context,
  ) as ILocationContextProviderProps
  const [zipCode, setZipCode] = useState('')

  const toggleLocationView = (evt: React.SyntheticEvent<HTMLElement>) => {
    evt.preventDefault()
    setShowLocationSelect(!showLocationSelect)
    setZipCode('')
  }

  const closeLocationView = (evt: React.SyntheticEvent<HTMLElement>) => {
    evt.preventDefault()
    setZipCode('')
    setShowLocationSelect(false)
  }

  const changeUserLocation = async (evt: any) => {
    evt.preventDefault()
    await setLocationByZip(zipCode)
    props.onLocationChange()
    setZipCode('')
  }

  const renderClosestStoreData = (store: IStoreLocation) => {
    return (
      <div onClick={() => (window.location.href = `/store/${store.slug}`)}>
        <div className={css.closestStoreName}>{store.name}</div>
        <div className={css.closestStoreDetail}>{store.streetAddress1}</div>
        <div className={css.closestStoreDetail}>{`${store.city}, ${store.state} ${store.zip}`}</div>
        <div className={css.closestStoreDetail}>{store.phone}</div>
        <div className={css.closestStoreOpenTill}>{renderOpenHours(store)}</div>
      </div>
    )
  }

  const showClosestStore = () => {
    return myClosestStore
      ? {
          message: <div className={css.deliveryAreaMessage}>Your delivery area</div>,
          detail: renderClosestStoreData(myClosestStore),
        }
      : {
          message: <div className={`${css.deliveryAreaMessage} ${css.outOfArea}`}>Out of delivery area</div>,
          detail: <div className={css.closestStoreMessage}>Outside of delivery area.</div>,
        }
  }

  const renderGeoContainer = () => {
    return (
      <div className={css.geoContainer}>
        <div>
          {location && (
            <>
              <FontAwesomeIcon icon={faMapMarkerAlt} className={css.geoIcon} title="Map Marker" focusable="false" />
              <div onClick={toggleLocationView} onKeyPress={toggleLocationView} className={css.geoDisplay} tabIndex={0}>
                <div>
                  {myClosestStore !== undefined ? (
                    <div>{showClosestStore().message}</div>
                  ) : (
                    <div style={{ color: 'white' }}>placeholder</div>
                  )}
                  <b>
                    <u>
                      {location.city}, {location.state}
                    </u>
                  </b>
                  <FontAwesomeIcon icon={faAngleDown} className={css.faIcon} title="Angle Down" focusable="false" />
                </div>
              </div>
            </>
          )}
        </div>
        <div
          className={`${css.locationBackground} ${showLocationSelect && css.showLocationDropdown}`}
          onClick={closeLocationView}
        />
        <div className={`${css.locationDropdown} ${showLocationSelect && css.showLocationDropdown}`}>
          <button className={css.close} title="Close" onClick={closeLocationView}>
            ×
          </button>
          <div className={css.geoHeader}>Change Your Location</div>
          <LocationByZipCodeInput value={zipCode} onSetZipCode={setZipCode} onChangeUserLocation={changeUserLocation} />
          <div className={css.geoFooter}>Closest Store to ZIP</div>
          <div>{showClosestStore().detail}</div>
          <a className={css.findAStoreButton} title="Find a store" href="/storelocator">
            FIND A STORE
          </a>
        </div>
      </div>
    )
  }

  return <div>{renderGeoContainer()}</div>
}

export default HeaderLocation
