import * as React from 'react'
import Footer from './Footer'
import { IPage } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/OmniPage/interfaces'
import { MinifiedFooter } from './MinifiedFooter'

export interface ILayoutProps {
  // TODO: create proper interfaces
  pageProps: any
  data: IPage[]
}
/**
 * The core wrapper for all pages
 */
const FooterLayout = (props: ILayoutProps) => {
  /**
   * Specific sections of the app will require a different header
   */
  const { pageProps } = props
  if (pageProps && pageProps.footer) {
    const { type } = pageProps.footer
    if (type && type === 'minified') {
      return <MinifiedFooter />
    }
  }
  return (
    <React.Fragment>
      <Footer footer={props.data} />
    </React.Fragment>
  )
}

export default FooterLayout
