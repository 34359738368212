import * as React from 'react'
import Router from 'next/router'
import css from './MobileHeader.scss'
import MobileSearchBar from './MobileSearchBar'
import { BurgerMenu } from './BurgerMenu/BurgerMenu'
import { LocationDropdown } from './LocationDropdown/LocationDropdown'
import HeaderContentBlocks from '../HeaderContentBlocks'
import MobileHeader from './MobileHeader'
import { SEARCH_INPUT_LABEL } from '../../../../../settings/variables'

interface IState {
  showBurgerMenu: boolean
  showLocationDropdown: boolean
  showInputSearch: boolean
}

class MobileMenu extends React.Component<any, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      showBurgerMenu: false,
      showLocationDropdown: false,
      showInputSearch: false,
    }
  }

  toggleBurgerMenu = () => {
    this.setState({ showBurgerMenu: !this.state.showBurgerMenu })
  }
  toggleLocationDropdown = () => {
    this.setState({ showLocationDropdown: !this.state.showLocationDropdown })
  }

  navigateFromMenuItem = (value: string) => {
    Router.push(`/${value}`)
  }

  handleShowSearch = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    evt.preventDefault()
    this.setState({ showInputSearch: !this.state.showInputSearch })
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <HeaderContentBlocks headerBanner={this.props.contentblocks} />
          <MobileHeader
            cartCount={this.props.cartCount}
            onClickBurgerMenu={this.toggleBurgerMenu}
            onClickLocationMenu={this.toggleLocationDropdown}
          />
          <div className={css.searchAndItems}>
            <div className={css.top}>
              <div className={css.search}>
                <MobileSearchBar placeholder={SEARCH_INPUT_LABEL} onSearchChange={this.props.onSearchChange} />
                <div className="unbxd-autosuggest-results">
                  <div className="unbxd-as-wrapper unbxd-as-extra-right"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BurgerMenu
          items={this.props?.links[0]?.categories}
          showSale={this.props.dynamicMenu.showSale}
          showNew={this.props.dynamicMenu.showNew}
          burgerMenu={this.state.showBurgerMenu}
          toggleBurgerMenu={this.toggleBurgerMenu}
          sortItemsByTreeIndex={this.props.sortItemsByTreeIndex}
          cartCount={this.props.cartCount}
        />
        <LocationDropdown
          onChangeLocation={this.props.onChangeLocation}
          menuOpen={this.state.showLocationDropdown}
          toggleLocationDropdown={this.toggleLocationDropdown}
          cartCount={this.props.cartCount}
        />
      </React.Fragment>
    )
  }
}

export default MobileMenu
